import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaPenNib } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const WritingService = ({ data }) => {
  const seoData = {
    title: '文章作成（ライティング）｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      'SEOを意識した文章作成、魅力的なコンテンツ制作でサイトの価値を向上。ブログ記事、商品説明、企業紹介文など幅広く対応。',
    pageUrl: 'https://www.involve-in.jp/service/writing',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: '文章作成（ライティング）', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes; // Contentful から施工事例を取得

  return (
    <>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="文章作成（ライティング）"
        subtitle="魅力的な文章を作成"
        icon={FaPenNib}
        description="SEOを意識した文章作成、魅力的なコンテンツ制作でサイトの価値を向上。ブログ記事、商品説明、企業紹介文など幅広く対応。"
        seo={seoData}
        breadcrumbs={breadcrumbList}
        benefits={[
          {
            title: 'ユーザーに刺さる文章設計',
            description:
              'ターゲットの心を掴む、わかりやすく魅力的な文章を提供。',
          },
          {
            title: '業界に合わせた適切なライティング',
            description:
              '専門性が求められる業界でも、適切な言葉選びで信頼感を与える記事を作成。',
          },
          {
            title: '読みやすく伝わる文章',
            description:
              '読者がストレスなく情報を理解できるよう、構成と表現を工夫します。',
          },
        ]}
        flow={[
          {
            title: 'ヒアリング',
            duration: '1日〜',
            description:
              'お客様のご要望やターゲットを詳しくヒアリングし、最適な記事の方向性を決定。',
          },
          {
            title: 'キーワード・構成設計',
            duration: '2日〜',
            description: 'SEOを考慮し、検索に強いキーワード選定と構成を作成。',
          },
          {
            title: '記事作成・修正',
            duration: '1週間〜',
            description:
              'ライティングを行い、内容を確認しながら必要な修正を実施。',
          },
          {
            title: '納品・公開',
            duration: null,
            description:
              '完成した文章を納品し、サイトに掲載。またはCMSに直接入力も可能。',
          },
        ]}
        preparation={[
          {
            title: 'ターゲットの明確化',
            description: '誰に向けた文章なのか、どんな目的で書くのかを整理。',
          },
          {
            title: '参考記事の提示',
            description: 'イメージに近い記事があればご提示ください。',
          },
          {
            title: 'キーワードの選定',
            description:
              'SEOを考慮し、狙いたいキーワードがあればお知らせください。',
          },
          {
            title: '文章のトーン・スタイル',
            description:
              'フォーマル・カジュアルなど、希望するトーンを決めておくとスムーズです。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null,
        }))}
        price="¥30,000〜"
        cta="まずは無料相談"
      />
    </>
  );
};

export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default WritingService;
